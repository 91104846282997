//setup gfx assets
const gfxLib = {
    prof_bad: {
        0: require('@/assets/gfx/prof-bad1.png'),
        1: require('@/assets/gfx/prof-bad2.png'),
        2: require('@/assets/gfx/prof-bad3.png'),
        3: require('@/assets/gfx/prof-bad4.png'),
    },
    prof_good: {
        0: require('@/assets/gfx/prof-tea.png'),
        1: require('@/assets/gfx/prof-tea2.png'),
        2: require('@/assets/gfx/prof-kitten.png'),
    },

};

export default {
    gfxLib
}
