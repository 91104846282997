//setup avatar files
const avatarLib = {
    0: {
        1: require('@/assets/gfx/avatars/bat-unscreen-static.png'),
        2: require('@/assets/gfx/avatars/bird-unscreen-static.png'),
        3: require('@/assets/gfx/avatars/cat-unscreen-static.png'),
        4: require('@/assets/gfx/avatars/elephant-unscreen-static.png'),
        5: require('@/assets/gfx/avatars/fox-unscreen-static.png'),
        6: require('@/assets/gfx/avatars/frog-unscreen-static.png'),
        7: require('@/assets/gfx/avatars/gecko-unscreen-static.png'),
        8: require('@/assets/gfx/avatars/gorilla-unscreen-static.png'),
        9: require('@/assets/gfx/avatars/lion-unscreen-static.png'),
        10: require('@/assets/gfx/avatars/monkey-unscreen-static.png'),
        11: require('@/assets/gfx/avatars/mouse-unscreen-static.png'),
        12: require('@/assets/gfx/avatars/orangutan-unscreen-static.png'),
        13: require('@/assets/gfx/avatars/owl-unscreen-static.png'),
        14: require('@/assets/gfx/avatars/parrot-unscreen-static.png'),
        15: require('@/assets/gfx/avatars/pig-unscreen-static.png'),
        16: require('@/assets/gfx/avatars/rockinghorse-unscreen-static.png'),
        17: require('@/assets/gfx/avatars/sabertooth-unscreen-static.png'),
        18: require('@/assets/gfx/avatars/sea-turtle-unscreen-static.png'),
        19: require('@/assets/gfx/avatars/snake-unscreen-static.png'),
        20: require('@/assets/gfx/avatars/tiger-unscreen-static.png'),
    },
    1: {
        1: require('@/assets/gfx/avatars/bat-unscreen.gif'),
        2: require('@/assets/gfx/avatars/bird-unscreen.gif'),
        3: require('@/assets/gfx/avatars/cat-unscreen.gif'),
        4: require('@/assets/gfx/avatars/elephant-unscreen.gif'),
        5: require('@/assets/gfx/avatars/fox-unscreen.gif'),
        6: require('@/assets/gfx/avatars/frog-unscreen.gif'),
        7: require('@/assets/gfx/avatars/gecko-unscreen.gif'),
        8: require('@/assets/gfx/avatars/gorilla-unscreen.gif'),
        9: require('@/assets/gfx/avatars/lion-unscreen.gif'),
        10: require('@/assets/gfx/avatars/monkey-unscreen.gif'),
        11: require('@/assets/gfx/avatars/mouse-unscreen.gif'),
        12: require('@/assets/gfx/avatars/orangutan-unscreen.gif'),
        13: require('@/assets/gfx/avatars/owl-unscreen.gif'),
        14: require('@/assets/gfx/avatars/parrot-unscreen.gif'),
        15: require('@/assets/gfx/avatars/pig-unscreen.gif'),
        16: require('@/assets/gfx/avatars/rockinghorse-unscreen.gif'),
        17: require('@/assets/gfx/avatars/sabertooth-unscreen.gif'),
        18: require('@/assets/gfx/avatars/sea-turtle-unscreen.gif'),
        19: require('@/assets/gfx/avatars/snake-unscreen.gif'),
        20: require('@/assets/gfx/avatars/tiger-unscreen.gif'),
    },

};

export default {
    avatarLib
}
