<template>
  
    <div class="">

        <div v-if="gameStore.currentGame.gameboard.isStart">

            <userhud />

            <div class="progress-wrapper" id="progress-wrapper">
                <div class="container px-0 pt-3 bg-white">
                    <div class="">
                        
                        <div v-if="(gameStore.currentGame.gameboard.isWin||gameStore.currentGame.gameboard.isLose) && !gameStore.currentGame.roundComplete" class="w-100 d-flex justify-content-center">
                            <div class="mb-3 w-50 text-center">
                                <button type="button" class="btn btn-primary text-uppercase w-100 d-flex align-items-center justify-content-center nextBtn" @click="newPuzzle(1);">
                                    <span>Next Puzzle</span>
                                    <i class="ms-2 fs-4 game-icon game-icon-bottom-right-3d-arrow"></i>
                                </button>
                            </div>
                        </div>

                        <div v-if="gameStore.currentGame.roundComplete" class="w-100 d-flex justify-content-center">
                            <div class="mb-3 w-50 text-center">
                                <button v-if="gameSummary" type="button" class="btn btn-primary text-uppercase w-100 d-flex align-items-center justify-content-center nextBtn" @click="startGame();">
                                    <span>Play Again</span>
                                    <i class="ms-2 fs-4 game-icon game-icon-clockwise-rotation"></i>
                                </button>
                                <button v-if="!gameSummary" type="button" class="btn btn-primary text-uppercase w-100 d-flex align-items-center justify-content-center nextBtn" @click="showGameSummary()">
                                    <span>Results</span>
                                    <i class="ms-2 fs-4 game-icon game-icon-pointing"></i>
                                </button>
                            </div>
                        </div>

                        <div class="score-bottom fs-4 p-1">
                            <div class="position-relative px-4" id="totalScore">
                                <div class="progress position-absolute" :class="{ 'd-none' : !gameStore.currentGame.totalScoreProgress }" id="totalScoreProgress">
                                    <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" style="width:100%;"></div>
                                </div>

                                <div class="position-absolute w-100">
                                    <div v-if="gameStore.currentGame.puzzleIdx>1 || gameStore.currentGame.gameboard.isWin" class="">
                                        <div class="text-uppercase fs-6 success-rate-text">{{ totalSuccess }} success rate</div>
                                    </div>
                                    <div v-else class="fs-6 text-uppercase">
                                        New Game Started
                                    </div>
                                </div>
                            </div>
                            <div class="text-uppercase px-4 d-flex align-items-center justify-content-between">
                                <div>
                                    <span v-if="puzzlesRemain>0">{{ puzzlesRemain }} puzzles remaining</span>
                                    <span v-if="!puzzlesRemain && !gameStore.currentGame.roundComplete">Last Puzzle!</span>
                                    <span v-if="!puzzlesRemain && gameStore.currentGame.roundComplete">Game Over</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    {{ this.$root.numFormat(gameStore.currentGame.totalScore) }}
                                    <i class="ms-1 icon-image icon-coin icon-small"></i>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-4" v-if="!gameSummary">
                <div class="score-top fs-4 p-1 d-flex align-items-center justify-content-between">
                    <div v-if="gameStore.currentGame.gameboard.isWin" class="ps-1 text-uppercase">
                        Cha ching!
                    </div>
                    <div v-else class="ps-1 text-uppercase">
                        Solve to earn
                    </div>

                    <div class="d-flex align-items-center justify-content-end">
                        <div v-if="!gameStore.currentGame.gameboard.isWin && userStore.user.options.timer">
                            <div class="fw-bold d-flex align-items-center justify-content-end timer me-2" :class="{ 'anim vibrate-1' : clueTimer.warning }" v-if="clueTimer.time>0">
                                <span class="text-end">{{ clueTimer.time }}</span>
                                <i class="ms-1 game-icon game-icon-stopwatch"></i>
                            </div>
                            <div class="fw-bold text-danger fs-2 d-flex align-items-center justify-content-end me-2 anim scale-in-center" v-if="clueTimer.time<=0">
                                <i class="game-icon game-icon-stopwatch"></i>
                            </div>
                        </div>
                        <div class="fw-bold d-flex align-items-center justify-content-center" :class="{ 'celeb' : gameStore.currentGame.gameboard.isWin, 'bust' : gameStore.currentGame.gameboard.isLose }" id="tvcAnimate">
                            <span id='tvcAnimateVal'>{{ gameStore.currentGame.puzzle.totalValueCurrent }}</span> 
                            <i id='tvcAnimateIcon' class="ms-1 icon-image icon-coin icon-small"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-4" v-if="gameSummary">
                <div class="score-top fs-4 p-1 d-flex align-items-center justify-content-center">
                    <div class="ps-1 text-uppercase">
                        Game Results
                    </div>
                </div>
            </div>
            <div id="gameSummaryMarker"></div>

            <div id="gameboard-wrapper" :class="{ 'gameboard-height' : gameBoardHeight() }" :key="gameboardKey">

                <div v-if="!gameStore.currentGame.gameboard.isWin&&!gameStore.currentGame.gameboard.isLose" class="phrase-wrapper mb-1 px-4">
                    <div class="phrase-inner fs-5 phrase-play w-100">
                        <div id="phrase-inner"  class="d-flex align-items-center justify-content-center" :class="{ 'flex-nowrap' : !phraseWrap(), 'flex-wrap' : phraseWrap() }">
                            <template v-for="p in phraseParts()">
                                <div v-if="p.slot" class="width-me" :class="{ 'border-success border-5 border-bottom':this.slotsCorrect.includes(p.part), 'border-danger border-5 border-bottom' : this.slotsCorrect.length&&this.assignWord.slots[p.slot]&&!this.slotsCorrect.includes(p.part) }">
                                    <div v-droppable:wordSlot @v-drag-drop="wordDrop" @v-drag-over="wordDropHover">
                                        <div class="mx-2 mb-1 cursor-pointer" :class="{ 'blank-noguess' : assignWord.startedBlank!=p.slot && !assignWord.started && !assignWord.slots[p.slot], 'blank-guess' : assignWord.startedBlank==p.slot||(assignWord.started && !assignWord.slots[p.slot]) }" :data-tk="parseTk(p.part)" :data-slot="p.slot" @click="selectTarget($event)">
                                            <span v-if="assignWord.slots[p.slot]" class="pt-2 mb-1 badge rounded-pill fw-normal text-uppercase cursor-pointer" :class="{ 'anim word-assign-in' : assignWord.slots[p.slot], 'anim word-assign-out' : assignWord.unselect.hasOwnProperty(parseTk(p.part)) }" @click="unselectWord(p)"  :data-tk="parseTk(p.part)" :data-slot="p.slot" :data-tk-inline="1"  v-draggable:wordSlot.move="{ data: { tkSlot: p.slot, tkAssign: assignWord.slots[p.slot] } }">
                                                {{ wordTk(assignWord.slots[p.slot]) }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="width-me" :class="{ 'me-1' : p.isPunct, 'mx-1' : !p.isPunct }">
                                    {{ p.part }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="mt-1 d-flex justify-content-center" v-if="gameStore.currentGame.puzzle.sourceRedacted">
                        <div class="d-flex flex-column align-items-center">
                            <div class="fs-6 fw-bold text-uppercase by-source">From the Movie</div><div class="mt-2 fw-normal fs-5 text-center text-uppercase phrase-author d-flex flex-wrap justify-content-center align-items-center" v-html="gameStore.currentGame.puzzle.sourceRedacted"></div>
                        </div>
                    </div>

                    <div id="phraseWinMarker"></div>
                </div>

                <div class="d-flex flex-column align-items-center">
                    <div class="wordcloud mt-3 p-2" id="wordcloud" v-if="!gameStore.currentGame.gameboard.isWin&&!gameStore.currentGame.gameboard.isLose"> 
                        <wordcloud ref="wordCloud" v-bind:assignWord="assignWord" />
                    </div>
                </div>

                <div class="d-flex justify-content-center my-3" v-if="!gameStore.currentGame.gameboard.isWin&&!gameStore.currentGame.gameboard.isLose">
                    <button type="button" class="tron sm" :disabled="submitPuzzleBtn" @click="submitPuzzle()">
                        <span></span><span></span><span></span><span></span>
                        solve
                    </button>
                </div>
            </div>
            
        </div>

    </div>

    <div v-if="(gameStore.currentGame.gameboard.isWin||gameStore.currentGame.gameboard.isLose) && !gameSummary" class="">
        <div  class="mt-3 mb-1 px-2">
            <div class="phrase-inner phrase-win">
                <div class="text-center" v-html="gameStore.currentGame.puzzle.phrase"></div>
                <div class="mt-2 text-center author" v-html="gameStore.currentGame.puzzle.author"></div>
            </div>
        </div>
        <div id="phrase-desc-wrapper" :class="{ 'desc-height' : phraseDescHeight() }">
            <div id="phrase-desc" class="phrase-desc fs-5 p-3" v-html="defnFormatted"></div>
        </div>
    </div>

    <div v-if="gameSummary" class="px-2 fs-3" id="gameSummary" :class="{ 'height' : gameSummaryHeight() }">

        <div class="mb-1">
            <div class="mb-3" v-for="pz in gameStore.currentPuzzles">
                <div class="fs-5 text-uppercase">&ldquo;{{ pz.puzzle.phrase }}&rdquo; &mdash; <span class="fs-6 fst-italic">{{ pz.puzzle.author }}</span></div>

                <div class="d-flex align-items-start justify-content-between">
                    <div class="d-flex align-items-center justify-content-start">
                        <div class="fs-6 text-success" v-if="pz.history?.guessWrong==0">
                            <span class="badge rounded-pill bg-success text-white">Perfect</span>
                        </div>
                        <div class="ms-2 fs-6 text-danger" v-if="pz.history?.guessWrong>0">
                            <span class="badge rounded-pill bg-danger text-white">{{ pz.history?.guessWrong }} BAD TRIES</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-end">
                        <div class="fs-5 d-flex align-items-center">
                            {{ pz.history?.finalValue }} <i class="ms-2 icon-image icon-coin icon-small"></i>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <div v-if="Object.keys(gameStore.currentGame.bonusHistory).length">
            <hr/>

            <h2 class="text-uppercase text-primary">Bonuses</h2>

            <div v-if="gamePerfectSolves()">
                <div class="d-flex align-items-center justify-content-between">
                    <span class="text-uppercase text-success fs-6">Perfect Solves ({{ gamePerfectSolves() }})</span>
                    <div class="fs-5 d-flex align-items-center">
                        {{ gamePerfectSolves() *  $root.calcBonus('perfectsolve') }} <i class="ms-2 icon-image icon-coin icon-small"></i>
                    </div>
                </div>            
            </div>

            <div v-for="(total,type) in gameStore.currentGame.bonusHistory">
                <div class="d-flex align-items-center justify-content-between">
                    <span class="text-uppercase text-success fs-6">{{ bonusName(type) }}</span>
                    <div class="fs-5 d-flex align-items-center">
                        {{ total }} <i class="ms-2 icon-image icon-coin icon-small"></i>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="Object.keys(gameStore.currentGame.penaltyHistory).length">
            <hr/>

            <h2 class="text-uppercase text-danger">Penalty</h2>

            <div v-for="pn in gameStore.currentGame.penaltyHistory">
                <div class="d-flex align-items-center justify-content-between">
                    <span class="text-uppercase text-danger fs-6">{{ pn.text }}</span>
                    <div class="fs-5 d-flex align-items-center">
                        - {{ pn.val }} <i class="ms-2 icon-image icon-coin icon-small"></i>
                    </div>
                </div>
            </div>
        </div>        

        <div class="d-flex align-items-center justify-content-between">
            <span class="text-uppercase text-success">total</span>
            <div class="fs-5 d-flex align-items-center">
                {{ gameStore.currentGame.totalScore }} <i class="ms-2 icon-image icon-coin icon-small"></i>
            </div>
        </div>


        <div v-if="0">
            <div class="d-flex align-items-center justify-content-between">
                <span>Total Puzzles</span>
                <span class="badge rounded-pill bg-dark text-white">
                    {{ this.gameStore.currentPuzzles.length }}
                </span>
            </div>
            <div class="d-flex align-items-center justify-content-between text-success">
                <span>Correct Guesses</span>
                <span class="badge rounded-pill bg-success text-white">
                    {{ this.userStore.user.totals.guessRight }}
                </span>
            </div>
            <div class="d-flex align-items-center justify-content-between text-danger">
                <span>Wrong Guesses</span>
                <span class="badge rounded-pill bg-danger text-white">
                    {{ this.userStore.user.totals.guessWrong }}
                </span>
            </div>
        </div>

    </div>

    <div v-if="gameStore.currentGame.bonus==1" class="popup" :class="cssAnim(popupOut)">
        <div class="d-flex justify-content-center">
            <div class="popup-body bonus-1 anim pulsate-fwd" @click="gameStore.currentGame.bonus=0;newAward=0;">
                <div class="d-flex align-items-start justify-content-start">
                    <img :src="$root.randomAsset($root.gfxLib.prof_good)" class="mascot" :class="{ 'anim mascot-entrance' : gameStore.currentGame.bonus==1 }" />

                    <div class="d-flex flex-column align-items-start">
                        <h1 class="">Quite Acceptable</h1>

                        <div class="d-flex flex-column ">
                            <h2>
                                Success Over {{ gameStore.bonus.diff[userStore.user.options.diff].min }}%
                            </h2>
                            <h1 class="award d-flex">
                                <div>+ {{ $root.calcBonus('base') }}</div> <i class="d-block ms-2 icon-image icon-coin icon-small"></i>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="gameStore.currentGame.bonus==2" class="popup" :class="cssAnim(popupOut)">
        <div class="d-flex justify-content-center">
            <div class="popup-body bonus-2 anim pulsate-fwd" @click="gameStore.currentGame.bonus=0;newAward=0;">
                <div class="d-flex align-items-start justify-content-start">
                    <img :src="$root.randomAsset($root.gfxLib.prof_good)" class="mascot" :class="{ 'anim mascot-entrance' : gameStore.currentGame.bonus==2 }" />

                    <div class="ms-2 d-flex flex-column align-items-start">
                        <h1 class="">Tip-Top</h1>

                        <div class="d-flex flex-column ">
                            <h2>
                                Perfect Game!
                            </h2>
                            <h1 class="award d-flex flex-column align-items-start">
                                <div class="d-flex align-items-start"><span>+ Shield</span> <img class="ms-3 icon-shield" src="@/assets/gfx/shield.png" /></div>
                                <div class="d-flex">
                                    <div>+ {{ $root.calcBonus('base-perfect') + $root.calcBonus('immunity') }}</div> <i class="d-block ms-2 icon-image icon-coin icon-small"></i>
                                </div>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="gameStore.currentGame.bonus==3" class="popup" :class="cssAnim(popupOut)">
        <div class="d-flex justify-content-center">
            <div class="popup-body bonus-3 anim pulsate-fwd" @click="gameStore.currentGame.bonus=0;newAward=0;endgameBonus();">
                <div class="d-flex align-items-center justify-content-start">
                    <img :src="$root.randomAsset($root.gfxLib.prof_good)" class="mascot" :class="{ 'anim mascot-entrance' : gameStore.currentGame.bonus==3 }" />
                    
                    <div class="d-flex flex-column align-items-center">
                        <h1 class="">Good Show</h1>
                        <h2>
                            Perfect Solve!
                        </h2>
                        <h1 class="award d-flex">
                            <div>+ {{ $root.calcBonus('perfectsolve') }}</div> <i class="d-block ms-2 icon-image icon-coin icon-small"></i>
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="newAward" class="popup bonus-award">
        <div class="d-flex justify-content-center">
            <div class="popup-body anim slide-in-left" @click="gameStore.currentGame.bonus=0;newAward=0">
                <div class="d-flex flex-column align-items-center">
                    <h1 class="text-success">New Award!</h1>
                    <h1 class="award anim pulsate-fwd">
                        <i :class="newAward.icon" :style="'color:' + newAward.color"></i>
                    </h1>
                </div>
            </div>
        </div>
    </div>

    <div v-if="gameStore.currentGame.shieldBlock" class="popup shieldblock anim puff-in-out-center">
        <div class="d-flex justify-content-center">
            <div class="popup-body" @click="gameStore.currentGame.bonus=0">
                <div class="d-flex flex-column align-items-center">
                    <img src="@/assets/gfx/kapow-clipart-md.png" style="width:100%;" />
                    <h1 class="text-warning text-uppercase">Bad Guess</h1>
                    <i class=" fs-1 game-icon game-icon-checked-shield "></i> Penalty Blocked!
                </div>
            </div>
        </div>
    </div>

    <div v-if="gameStore.currentGame.gameboard.isLosePop" class="popup" :class="cssAnim(popupOut)">
        <div class="d-flex justify-content-center">
            <div class="popup-body lose-1 anim pulsate-fwd" @click="gameStore.currentGame.gameboard.isLosePop=0;endgameBonus()">
                <div class="d-flex align-items-center justify-content-start">
                    <img :src="$root.randomAsset($root.gfxLib.prof_bad)" class="mascot" :class="{ 'anim mascot-entrance' : gameStore.currentGame.gameboard.isLosePop }" />

                    <div class="ms-2 d-flex flex-column align-items-start">
                        <h1 class="">Ouch</h1>
                        <h2 style="font-size:60%;">
                            You earned nothing!
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <alert-dialog ref="alertDialog"></alert-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import { draggable, droppable } from 'v-drag-drop';
import userhud from "@/components/userhud.vue";
import wordcloud from "@/components/wordcloud.vue";
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

import "@/DragDropTouch.js";

export default {
    components: {
        userhud,
        wordcloud,
        alertDialog, 
        confirmDialog
    },
    directives: {
        draggable, droppable
    },
    data: () => ({   
        userStore: useUserStore(),
        gameStore: useGameStore(),
        sfxObj: {
            startGame: 0,
            wordWrong: 0,
            wordRight: 0,
            winPhrase: 0,
            nextPuzzle: 0,
        },
        submitPuzzleBtn: 0,
        tvcAnimate: 0,
        gameSummary: 0,
        newAward: 0,
        clueTimer: {},
        popupOut: 0,
        wordCloud: {},
        slotsCorrect: [],
        gameboardKey: 1,
        assignWord: {
            started: 0,
            source: 0,
            target: 0,
            slots: {},
            unselect: {},
        },
    }),
    computed: {
        sfxLib: function() {
            return this.$root.sfxLib;
        },
        genKey: function() {
            return this.gameStore.currentGame.gameboard.token+"_"+Math.random(0,1000);
        },
        defnFormatted: function() {
            return this.gameStore.currentGame.puzzle.defn.replace(/(?:\r\n|\r|\n)/g, '<br>');
        },
        totalSuccess: function(){
            return !this.gameStore.currentGame.gameboard.guessTotal ? '-' : this.gameStore.currentGame.totalScoreProgress + "%";
            //return !this.gameStore.currentGame.totalScoreMax ? '-' : Math.round((this.gameStore.currentGame.totalScore/this.gameStore.currentGame.totalScoreMax)*100) + "%";
        },
        puzzlesRemain: function(){
            return this.gameStore.currentPuzzles.length - this.gameStore.currentGame.puzzleIdx;
        },
        guessCount: function() {
            var count = 0;
            for (let x = 0; x < this.gameStore.currentGame.puzzle.tokens.length; x++) {
                if ( this.gameStore.currentGame.puzzle.tokens[x].hasOwnProperty('guess') ) {
                    count++;
                }
            }
            return count;
        },
    },
    updated: function(){

        if ( !this.gameStore.currentGame.gameboard.isStart ) {
            return;
        }

        if ( this.gameStore.currentGame.gameboard.isWin) {
            //console.log('iswin!');

            // this.$root.playSfx(this.$root.sfxObj.bgTune,'',{stop:1});
            // this.$root.sfxObj.bgTune = 0;

            return;
        }

        if ( this.gameStore.currentGame.gameboard.isLose) {
            return;
        }

        var maxWidth = document.querySelector('.score-top');
        var div = document.querySelector('.phrase-play');
        var divWidth = div.offsetWidth + 22;


    },
    methods: {
        wordDropHover: function(dropData,d,evt){
            var slot = evt.target.getAttribute('data-slot');
            if ( !this.assignWord.slots[slot] ) {
                this.assignWord.startedBlank = slot;
            }
        },
        wordDrop: function(dropData,dropOK,evt){
            this.slotsCorrect = [];
            console.log('ondrop',dropData,evt);
            var tk = evt.target.getAttribute('data-tk');
            var slot = evt.target.getAttribute('data-slot');
            if ( !tk || !slot ) { return; }

            this.assignWord.startedBlank = null;
            if ( evt.target.getAttribute('data-tk-inline') ) {
                var tkMove = this.assignWord.slots[slot];
                if ( dropData.data.hasOwnProperty('tkAssign') ) {
                    this.assignWord.slots[slot] = dropData.data.tkAssign;
                    this.assignWord.slots[dropData.data.tkSlot] = tkMove;
                } else {
                    this.assignWord.slots[slot] = dropData.data.tk;
                }
                this.$refs.wordCloud.isAssigned();
                this.assignWord.started = 0;
                this.assignWord.source = 0;
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.wordSlot,{});
                return;
            } else if ( dropData.data.hasOwnProperty('tkSlot') ) {
                this.assignWord.slots[slot] = dropData.data.tkAssign;
                this.assignWord.slots[dropData.data.tkSlot] = 0;
                this.$refs.wordCloud.isAssigned();
                this.assignWord.started = 0;
                this.assignWord.source = 0;
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.wordSlot,{});
                return;
            }

            if ( Object.values(this.assignWord.slots).includes(dropData.data.tk) ) { return; }

            this.assignWord.slots[slot] = dropData.data.tk;
            this.$refs.wordCloud.isAssigned();
            this.assignWord.started = 0;
            this.assignWord.source = 0;
            this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.wordSlot,{});
        },
        redactSource: function(word) {
            var letters;
            if ( this.userStore.user.options.diff == 1 ) {
                letters = Math.floor(word.length * 0.25);
            } else if ( this.userStore.user.options.diff == 3 ) {
                letters = Math.floor(word.length * 0.50);
            } else {
                letters = Math.floor(word.length * Math.random());
            }
            //console.log('redact',letters);

            let chars = word.split('');
            let length = chars.length;
            let replacements = 0;

            while (replacements < letters) {
                const randomIndex = Math.floor(Math.random() * length);
                if (chars[randomIndex] !== '*') {
                    chars[randomIndex] = '*';
                    replacements++;
                }
            }

            for (let x = 0; x < chars.length; x++) {
                if (chars[x] == '*') {
                    chars[x] = chars[x].replace("*", "<span class='fs-6 mx-1 redact'>*</span>");
                }
            }

            return chars.join('');
        },
        bonusName: function(type) {
            switch (type) {
                case 'immunity':
                    return "Perfect Game + 1 Shield"
                    break;
                case 'base':
                    return "High Performer"
                    break;                    
            
                default:
                    return '';
                    break;
            }
        },
        gamePerfectSolves: function() {
            var c = 0;

            for (let x = 0; x < this.gameStore.currentPuzzles.length; x++) {
                if ( this.gameStore.currentPuzzles[x].history.hasOwnProperty('perfectSolve') ) {
                    c++;
                }
            }
            return c;
        },
        phraseWrap: function() {
            return true;

            var el = document.querySelectorAll('#phrase-inner .width-me');
            var w = 0;
            var body = document.getElementById('bg-overlay');
            if ( !body ) { return true; }
            
            if ( el ) {
                for (let x = 0; x < el.length; x++) {
                    w = w + el[x].clientWidth;
                }
                return w>body.clientWidth;
            }

            return true;
        },  
        gameBoardHeight: function() {
            if ( !document.getElementById('gameSummaryMarker') ) {
                return;
            }

            if ( this.gameStore.currentGame.gameboard.isWin 
            || this.gameStore.currentGame.gameboard.isLose ) {
                document.getElementById('app').style.setProperty('--gameboard-h',0);
                return;
            }

            var safeTop = getComputedStyle(document.getElementById('app')).getPropertyValue("--safet").replace('px','');

            document.getElementById('app').style.setProperty('--gameboard-h', ( document.body.offsetHeight - document.getElementById('gameSummaryMarker').offsetTop - document.body.querySelector('.progress-wrapper').offsetHeight - safeTop) * .95 + 'px');

            return true;
        },
        phraseDescHeight: function() {
            if ( !document.getElementById('phraseWinMarker') ) {
                return;
            }

            var safeTop = getComputedStyle(document.getElementById('app')).getPropertyValue("--safet").replace('px','');

            document.getElementById('app').style.setProperty('--phrase-desc-h', ( document.body.offsetHeight - document.getElementById('phraseWinMarker').offsetTop - document.body.querySelector('.progress-wrapper').offsetHeight - safeTop) * .85 + 'px');

            return true;
        },
        gameSummaryHeight: function() {
            if ( !document.getElementById('gameSummaryMarker') ) {
                return;
            }

            var h = document.body.offsetHeight - document.getElementById('gameSummaryMarker').offsetTop - document.body.querySelector('.progress-wrapper').offsetHeight;

            var safeTop = getComputedStyle(document.getElementById('app')).getPropertyValue("--safet").replace('px','');

            document.getElementById('app').style.setProperty('--game-summary-h', ( document.body.offsetHeight - document.getElementById('gameSummaryMarker').offsetTop - document.body.querySelector('.progress-wrapper').offsetHeight - safeTop ) * .95 + 'px');

            return true;
        } ,
        gameProgress: function(guessRight,guessTotal) {
            var p = Math.round( (guessRight / guessTotal) * 100 );
            this.gameStore.currentGame.totalScoreProgress = p;
            document.getElementById('totalScoreProgress').style.setProperty('--progress', p + '%');
        },
        boardReady: function() {
            if ( !this.gameStore.currentGame.gameboard.isReady ) {
                this.gameStore.currentGame.gameboard.isReady = 1;
            }
            this.$root.sfxObj.bgTune = this.$root.playSfx(this.$root.sfxObj.bgTune,this.sfxLib.bgTheme,{bgTune:1});
            this.gameboardKey = Math.floor(Math.random() * 1000);
        },
        parseTk: function(s) {
            return s.replaceAll(/[^a-b0-9]/ig,'');
        },
        wordTk: function(tk) {
            var word = '';

            for (let x = 0; x < this.wordCloud.length; x++) {
                if ( this.wordCloud[x].token == tk ) {
                    word = this.wordCloud[x].word;
                }
            }

            return word;
        },
        selectWord: function(tk) {
            this.slotsCorrect = [];
            //console.log('selectword',this.assignWord.started,tk);

            if ( Object.values(this.assignWord.slots).includes(tk) ) { return; }

            if ( this.assignWord.startedBlank ) {
                this.assignWord.slots[this.assignWord.startedBlank] = tk;
                this.$refs.wordCloud.isAssigned();
                this.assignWord.started = 0;
                this.assignWord.startedBlank = null;
                this.assignWord.source = 0;
                this.$root.playSfx(this.$root.sfxObj.wordActivate,this.$root.sfxLib.wordActivate,{fadeOut:500});
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.wordSlot,{});
                return; 
            }

            if ( this.assignWord.started && this.assignWord.source == tk ) {
                this.assignWord.started = 0;
                this.assignWord.source = 0;
                this.$root.playSfx(this.$root.sfxObj.wordActivate,this.$root.sfxLib.wordActivate,{fadeOut:500});
            } else {
                this.assignWord.started = 1;
                this.assignWord.source = tk;
                this.$root.sfxObj.wordActivate = this.$root.playSfx(this.$root.sfxObj.wordActivate,this.$root.sfxLib.wordActivate,{});
            }
        },
        unselectWord: function(p) {
            this.slotsCorrect = [];
            this.assignWord.unselect[p.part] = 1;
            var self = this;
            this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.swoosh,{});

            setTimeout(function(){
                delete self.assignWord.unselect[p.part];
                delete self.assignWord.slots[p.slot];
                self.assignWord.startedBlank = null;
                self.$refs.wordCloud.isAssigned();
            },250);
        },
        selectTarget: function(evt) {
            console.log('selecttarget', evt.target);
            this.$root.playSfx(this.$root.sfxObj.wordActivate,this.$root.sfxLib.wordActivate,{fadeOut:500});
            
            this.slotsCorrect = [];
            if ( this.assignWord.started ) {  
                var slot = evt.target.getAttribute('data-slot');
                this.assignWord.slots[slot] = this.assignWord.source;
                this.$refs.wordCloud.isAssigned();
                this.assignWord.started = 0;
                this.assignWord.startedBlank = null;
                this.assignWord.source = 0;
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.wordSlot,{});
            } else {
                var slot = evt.target.getAttribute('data-slot');
                if ( this.assignWord.startedBlank == slot ) {
                    this.assignWord.startedBlank = null;
                } else {
                    if ( !this.assignWord.slots[slot] ) {
                        this.assignWord.startedBlank = slot;
                        this.$root.sfxObj.wordActivate = this.$root.playSfx(this.$root.sfxObj.wordActivate,this.$root.sfxLib.wordActivate,{});
                    }
                }
            }
        },
        guessRight: function() {
            //console.log('guess correct');
            this.clueTimerRun('stop');

            //this.$root.sfxObj.bgTune = this.$root.playSfx(this.$root.sfxObj.bgTune,this.sfxLib.winPhraseTune,{});
            this.$root.playSfx(this.$root.sfxObj.bgTune,'',{fadeOut:0.5});

            this.gameStore.currentGame.gameboard.isReady = 0;

            this.gameStore.currentGame.gameboard.guessRight++;
            this.userStore.user.totals.guessRight++;
            this.gameStore.currentGame.gameboard.guessTotal++;
            this.userStore.user.totals.guessTotal++;

            var totalScore = Math.round(Number(this.gameStore.currentGame.totalScore) + Number(this.gameStore.currentGame.puzzle.totalValueCurrent));
            var totalScoreMax = Number(this.gameStore.currentGame.totalScoreMax) + Number(this.gameStore.currentGame.puzzle.totalValueMax);
            this.gameStore.currentGame.totalScore = totalScore;
            this.gameStore.currentGame.totalScoreMax = totalScoreMax;
            this.gameStore.currentGame.gameboard.isWin = 1;

            this.gameStore.currentPuzzles[this.gameStore.currentGame.puzzleIdx-1].history = JSON.parse(JSON.stringify(this.gameStore.currentGame.gameboard));
            this.gameStore.currentPuzzles[this.gameStore.currentGame.puzzleIdx-1].history.finalValue = this.gameStore.currentGame.puzzle.totalValueCurrent;
            this.submitPuzzleBtn = 0;

            //console.log('calc total score', totalScore, this.gameStore.currentGame.totalScore, this.gameStore.currentGame.puzzle.totalValueCurrent);

            this.updateSuccessRate();

            if ( this.gameStore.currentGame.puzzle.totalValueCurrent == this.gameStore.currentGame.puzzle.totalValueMax ) {
                this.ingameBonus('perfectsolve');
                this.gameStore.currentPuzzles[this.gameStore.currentGame.puzzleIdx-1].history.perfectSolve = 1;
            } else {
                this.endgameBonus();
            }
        },
        guessWrong: function() {
            var penalty;
            var tv = this.gameStore.currentGame.gameboard.totalValueStage;
            var wrong = this.gameStore.currentGame.gameboard.wrong?this.gameStore.currentGame.gameboard.wrong:0;
            wrong++;

            if ( this.userStore.user.tools.shield.active ) {
                this.userStore.user.tools.shield.total--;
                this.userStore.user.tools.shield.active = 0;
                this.gameStore.currentGame.shieldBlock = 1;
                this.$root.playSfx(this.$root.sfxObj.tmp,this.userStore.user.tools.shield.sound.hit,{});

                var self=this;
                setTimeout(function(){
                    self.gameStore.currentGame.shieldBlock = 0;
                    self.submitPuzzleBtn = 0;
                },6000);

                return;
            } 

            this.gameStore.currentGame.gameboard.guessWrong++;
            this.userStore.user.totals.guessWrong++;
            this.gameStore.currentGame.gameboard.guessTotal++;
            this.userStore.user.totals.guessTotal++;

            penalty = Math.round(Number(this.gameStore.currentGame.puzzle.totalValue) / Number(this.gameStore.currentGame.puzzle.phraseMatch.length));

            this.gameStore.currentGame.gameboard.wrong = wrong;

            var tvc = this.gameStore.currentGame.puzzle.totalValue - (penalty*wrong);
            //console.log('penalty',penalty,wrong,tvc);

            if ( tvc <= 0 ) {
                tvc = 0;
            }

            this.gameStore.currentPuzzles[this.gameStore.currentGame.puzzleIdx-1].history = JSON.parse(JSON.stringify(this.gameStore.currentGame.gameboard));
            this.gameStore.currentPuzzles[this.gameStore.currentGame.puzzleIdx-1].history.finalValue = tvc;

            var tvcCountdown = this.gameStore.currentGame.puzzle.totalValueCurrent;
            var self = this;
            document.getElementById('tvcAnimateVal').innerHTML = tvcCountdown;
            document.getElementById('tvcAnimate').classList.add("tvcAnimate");

            this.sfxObj.wordWrong = this.$root.playSfx(this.sfxObj.wordWrong,this.sfxLib.wordWrong,{});

            this.tvcAnimate = setInterval(function(){
                tvcCountdown--;
                document.getElementById('tvcAnimateVal').innerHTML = tvcCountdown;

                //console.log('countdown',tvc,tvcCountdown);
                if ( tvcCountdown < tvc || tvcCountdown == 0 ) {
                    document.getElementById('tvcAnimate').classList.remove("tvcAnimate");
                    self.gameStore.currentGame.puzzle.totalValueCurrent = tvc;
                    clearInterval(self.tvcAnimate);
                    self.tvcAnimate = 0;
                    self.submitPuzzleBtn = 0;
                    self.$root.playSfx(self.sfxObj.wordWrong,'',{fadeOut:0.5});

                    if ( tvcCountdown <= 0 ) {
                        self.gameStore.currentGame.puzzle.totalValueCurrent = 0;
                        self.bustGame();
                        return;
                    }
                }
            },75);

            //console.log('guesswrong',tv,wrong,penalty,this.gameStore.currentGame.puzzle.totalValueCurrent);
        },
        genWordCloud: function() {
            var wordcloud = [];
            var words = [];
            var guesses = [];

            var tkKey;
            for ( tkKey in this.gameStore.currentGame.puzzle.tokens ) {
                if ( this.gameStore.currentGame.puzzle.phraseMarkup.indexOf("{{" + tkKey + "}}") >= 0 ) { 
                    wordcloud.push({
                        token: tkKey,
                        word: this.gameStore.currentGame.puzzle.tokens[tkKey].word,
                    });
                    words.push(this.gameStore.currentGame.puzzle.tokens[tkKey].word);
                    guesses = guesses.concat(this.gameStore.currentGame.puzzle.tokens[tkKey].decoys);
                }
            }

            if ( this.userStore.user.options.diff == 3 ) {
                var wcl = wordcloud.length;
                var decoys = [];
                for (let x = 0; x < guesses.length; x++) {
                    if ( !words.includes(guesses[x]) ) {
                        decoys.push(guesses[x]);
                    }
                    
                }
                decoys = shuffleArray(decoys);
                var dcl = decoys.length>3?3:decoys.length;
                for (let x = 0; x < dcl; x++) {
                    var tk ='tk' + Number(wcl+1 +x);
                    wordcloud.push({
                        token: tk,
                        word: decoys[x],
                    });
                    this.gameStore.currentGame.puzzle.tokens[tk] = { word: decoys[x], decoys: [] };
                }
            }

            wordcloud = shuffleArray(wordcloud);

            return wordcloud;

        },
        solveWord: function() {
            var phraseTokens = this.gameStore.currentGame.puzzle.phraseMarkup.match(/\{\{tk[0-9+]\}\}?/g);
            var unassigned = [];
            for (let x = 0; x < phraseTokens.length; x++) {
                if ( !Object.values(this.assignWord.slots).includes(phraseTokens[x].replace(/[\{\}]+/g,'')) ) {
                    unassigned.push(phraseTokens[x]);
                }
            }
            
            if ( !unassigned.length ) {
                const ok = this.$refs.alertDialog.show({
                    title: 'Solving Wand',
                    message: "The solving wand can't find any unused clues!",
                });
                this.userStore.user.tools.wand.active = 0;
                return;
            }

            var solveTk = unassigned[Math.floor(Math.random() * unassigned.length)];
            var phraseParts = this.phraseParts();
            

            for (let x = 0; x < phraseParts.length; x++) {
                if ( solveTk == phraseParts[x].part ) {
                    this.assignWord.slots[phraseParts[x].slot] = solveTk.replace(/[\{\}]+/g,'');
                }
            }

            this.$root.playSfx(this.$root.sfxObj.tmp,this.userStore.user.tools.wand.sound.hit,{});

            if ( this.userStore.user.tools.wand.active ) {
                this.userStore.user.tools.wand.total--;
                this.userStore.user.tools.wand.active = 0;
            }

            this.gameStore.currentGame.gameboard.guessRight++;
            this.gameStore.currentGame.gameboard.guessTotal++;

        },
        unmaskSource: function() {
            if ( this.userStore.user.tools.unmask.active ) {
                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.unmaskActivate,{});

                this.$root.playSfx(this.$root.sfxObj.tmp,this.userStore.user.tools.wand.sound.hit,{});

                this.gameStore.currentGame.puzzle.sourceRedacted = this.gameStore.currentGame.puzzle.author;

                this.userStore.user.tools.unmask.total--;
                this.userStore.user.tools.unmask.active = 0;
            }
        },
        enableSpotlight: function() {
            if ( this.userStore.user.tools.spotlight.active ) {
                if ( !Object.values(this.assignWord.slots).length ) {
                    const ok = this.$refs.alertDialog.show({
                        title: 'Spotlight',
                        message: "No clues are in use yet!",
                    });
                    this.userStore.user.tools.spotlight.active = 0;
                    return;
                }

                this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.spotlightActivate,{});

                var result = this.evalPuzzle();
                if ( !result.correct.length ) {
                    result.correct = [0];
                }
                this.slotsCorrect = result.correct;
                this.userStore.user.tools.spotlight.total--;
                this.userStore.user.tools.spotlight.active = 0;

                var self = this;
                setTimeout(function(){
                    self.slotsCorrect = [];
                },5000);
            }
        },
        evalPuzzle: function() {
            //console.log('submit puzzle');
            var phraseMatch = [];
            var phraseTokens = this.gameStore.currentGame.puzzle.phraseMarkup.match(/\{\{tk[0-9+]\}\}?/g);
            //console.log('phraseTokens',phraseTokens);
            for (let x = 0; x < phraseTokens.length; x++) {
                //console.log(phraseTokens[x],'vs','{{'+this.assignWord.slots[x+1]+'}}');
                if ( phraseTokens[x] == '{{'+this.assignWord.slots[x+1]+'}}' ) {
                    phraseMatch[x] = phraseTokens[x];
                } else {
                    phraseMatch[x] = null;
                }
            }

            //console.log('phraseMatch',phraseMatch);
            var correct = [];
            for (let x = 0; x < phraseMatch.length; x++) {
                if (phraseMatch[x]) { correct.push(phraseMatch[x]); }
            }
            //console.log('correct',correct);

            this.gameStore.currentGame.puzzle.phraseMatch = phraseMatch;

            return { phraseMatch:phraseMatch, correct:correct }
        },
        submitPuzzle: function() {
            this.submitPuzzleBtn = 1;
            var result = this.evalPuzzle();

            var solve = JSON.stringify(result.phraseMatch)==JSON.stringify(result.correct);
            //console.log('solve',solve);

            if ( solve ) {
                this.guessRight();
            } else {
                this.guessWrong();
            }

        },
        currentboard: function(tokenIdx) {
            this.wordCloud = this.genWordCloud();
            return {
                    phrase: this.gameStore.currentGame.gameboard.phraseCurrent,
                    clues: this.gameStore.currentGame.gameboard.clues,
                }
        },
        setupStage() {
            var title = this.gameStore.currentGame.puzzle.phraseMarkup;

            Object.assign(this.gameStore.currentGame.gameboard, {
                phraseCurrent: title,
                totalValueStage: Math.round(this.gameStore.currentGame.puzzle.totalValueMax / this.guessCount),
                clues: [],
                cluelength: 0,
                answer: 0,
            });
            
            this.currentboard(this.gameStore.currentGame.gameboard.token);

            this.assignWord = {
                started: 0,
                startedBlank: 0,
                source: 0,
                target: 0,
                slots: {},
                unselect: {},
            };

            this.clueTimer.started = 0;
            this.clueTimerRun('start');
            this.boardReady();
        },
        phraseParts: function() {
            var phraseMarkup = this.gameStore.currentGame.puzzle.phraseMarkup;
            //phraseMarkup = phraseMarkup.replace("..."," ... ");

            const segmenter = new Intl.Segmenter([], { granularity: 'word' });
            var parts = [...segmenter.segment(phraseMarkup)];

            var parts2 = [];
            var idx = 0;
            for (let x = 0; x < parts.length; x++) {
                if ( parts[x].segment.match(/[\{\}]/) ) { continue; }

                if ( parts[x].segment.match(/tk[0-9+]/) ) {
                    idx++;
                    parts2.push({slot:idx,part:"{{"+parts[x].segment+"}}"});
                } else {
                    parts2.push({
                        slot:null,
                        part:parts[x].segment,
                        isPunct: /[^a-zA-Z0-9]/.test(parts[x].segment),
                    });
                }
            }

            this.gameStore.currentGame.puzzle.phraseMarkup = phraseMarkup;
            return parts2;
        },
        clueTimerRun: function(mode) {
            //console.log('clueTimerRun',mode,this.clueTimer);
            if ( !this.userStore.user.options.timer ) {
                return;
            }

            if (this.$root.globalTimer) {
                clearInterval(this.$root.globalTimer);
                this.$root.globalTimer = 0;
            }

            if ( mode == 'start' && !this.clueTimer.started ) {

                if ( document.querySelector('.timer') ) {
                    document.querySelector('.timer').style.setProperty('--timer-color', 'var(--bs-success)');
                } else {
                    this.clueTimerRun('stop');
                }

                this.clueTimer.timeInit = this.clueTimer.init.diff[this.userStore.user.options.diff];
                this.clueTimer.time = this.clueTimer.timeInit;
                this.clueTimer.started = 1;

                var self = this;
                self.$root.globalTimer = setInterval(function(){
                    if ( !document.querySelector('.timer') ) {
                        self.clueTimerRun('stop');
                        return;
                    } 

                    self.clueTimer.time--;

                    if ( self.clueTimer.time >= self.clueTimer.timeInit/2 ) {
                        self.clueTimer.warning = 0;
                        self.clueTimer.enableBonus = 1;
                        document.querySelector('.timer').style.setProperty('--timer-color', 'var(--bs-success)');
                        if ( self.clueTimer.time % 2 == 0 ) {
                            self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.timer.even.lo,{});
                        } else {
                            self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.timer.odd.lo,{});
                        }
                    } else if ( self.clueTimer.time > self.clueTimer.timeInit*.25 ) {
                        self.clueTimer.warning = 0;
                        self.clueTimer.enableBonus = 0;
                        document.querySelector('.timer').style.setProperty('--timer-color', 'var(--bs-warning)');
                        if ( self.clueTimer.time % 2 == 0 ) {
                            self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.timer.even.lo,{});
                        } else {
                            self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.timer.odd.lo,{});
                        }
                    } else {
                        self.clueTimer.warning = 1;
                        if ( self.clueTimer.time % 2 == 0 ) {
                            self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.timer.even.hi,{});
                        } else {
                            self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.timer.odd.hi,{});
                        }
                    } 

                    if ( self.clueTimer.time <= 0 ) {
                        self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.timer.buzzer,{});
                        self.gameStore.currentGame.timePenalty++;
                        self.clueTimer.enableBonus = 0;
                        self.bustGame();
                        self.clueTimerRun('stop');
                    }
                }, 1000);
            } else if ( mode == 'stop' ) {
                this.clueTimer.started = 0;
            }
        },        
        puzzleValue: function(puzzle) {
            var total = Object.keys(puzzle.tokens).length * this.gameStore.options.puzzleValue.length;
            
            total = total + (this.gameStore.options.puzzleValue.stopWord*(puzzle.length-Object.keys(puzzle.tokens).length));

            total = total + (Object.keys(puzzle.tokens).length*this.gameStore.options.puzzleValue.guessWord);

            return total;

        },
        updateSuccessRate: function() {
            this.userStore.user.totals.successRate = Math.round( (this.userStore.user.totals.guessRight / this.userStore.user.totals.guessTotal) * 100 );
            this.gameProgress(this.userStore.user.totals.guessRight,this.userStore.user.totals.guessTotal);
        },
        ingameBonus: function(type) {
            if ( type == 'perfectsolve' ) {
                this.gameStore.currentGame.bonus = 3;
                this.gameStore.currentGame.totalScore = Math.round( this.gameStore.currentGame.totalScore + this.$root.calcBonus('perfectsolve') );

                var self = this;
                setTimeout(function(){
                    self.$root.playSfxList('pfsolve',[
                    {sound:self.sfxLib.speech.good,options:{volume:1,tts:1}},{sound:self.sfxLib.bonusCharm,options:{volume:0.5}},
                    ]);
                },250);
            } 
        },
        endgameBonus: function() {
            // this.$root.playSfx(this.$root.sfxObj.bgTune,'',{stop:1});
            // this.$root.sfxObj.bgTune = 0;
            this.$root.sfxObj.bgTune = this.$root.playSfx(this.$root.sfxObj.bgTune,this.sfxLib.winPhraseTune,{bgTune:1});

            if ( this.gameStore.currentGame.puzzleIdx == this.gameStore.currentPuzzles.length ) {
                this.gameStore.currentGame.roundComplete = 1;

                if ( this.gameStore.currentGame.totalScoreProgress == 100 ) {
                    this.gameStore.currentGame.bonus = 2;
                    this.gameStore.currentGame.totalScore = this.gameStore.currentGame.totalScore + this.$root.calcBonus('base-perfect') + this.$root.calcBonus('immunity');

                    var self = this;
                    setTimeout(function(){
                        self.$root.playSfxList('perfectgame',[
                            {sound:self.sfxLib.speech.great,options:{volume:1,tts:1}},
                            {sound:self.sfxLib.bonusCharm,options:{volume:0.5}},
                        ]);
                    },250);

                    this.userStore.user.tools.shield.total++;

                    this.gameStore.currentGame.bonusHistory['immunity'] = this.$root.calcBonus('base-perfect') + this.$root.calcBonus('immunity');

                    this.userStore.user.totals.perfectGames.push({
                        diff: this.userStore.user.options.diff,
                        ppr: this.userStore.user.options.ppr,
                        score: this.gameStore.currentGame.totalScore,
                    });

                    var count = 0;
                    for (let X = 0; X < this.userStore.user.totals.perfectGames.length; X++) {
                        if ( (this.userStore.user.totals.perfectGames[X].diff == this.userStore.user.options.diff) && (this.userStore.user.totals.perfectGames[X].ppr == this.userStore.user.options.ppr) ) {
                            count++;
                        }
                        
                    }

                    if ( count % this.gameStore.bonus.diff[this.userStore.user.options.diff].award.wins == 0 ) {
                        var newAward = {
                            'icon': this.gameStore.bonus.diff[this.userStore.user.options.diff].award.icon,
                            'color': this.gameStore.options.ppr[this.userStore.user.options.ppr].color,
                        };
                        this.newAward = newAward;
                    } else {
                        this.newAward = 0;
                    }
                    
                } else if ( this.gameStore.currentGame.totalScoreProgress >= this.gameStore.bonus.diff[this.userStore.user.options.diff].min ) {
                    this.gameStore.currentGame.bonus = 1;
                    //console.log('endgame bonus', this.gameStore.currentGame.totalScore, this.gameStore.bonus.diff, this.userStore.user.options.diff,this.gameStore.bonus.diff[this.userStore.user.options.diff]);
                    this.gameStore.currentGame.totalScore = this.gameStore.currentGame.totalScore + this.$root.calcBonus('base');

                    var self = this;
                    setTimeout(function(){
                        self.$root.playSfxList('successrate',[
                            {sound:self.sfxLib.speech.good,options:{volume:1,tts:1}},
                            {sound:self.sfxLib.bonusCharm,options:{volume:0.5}},
                        ]);
                    },250);

                    this.gameStore.currentGame.bonusHistory['base'] = this.$root.calcBonus('base');
                } 
            }

            this.userStore.user.totals.score = this.userStore.user.totals.score + this.gameStore.currentGame.totalScore; 
            this.userStore.user.totals.rounds++;
            this.userStore.user.totals.puzzles = Number(this.userStore.user.totals.puzzles) + Number(this.userStore.user.options.ppr);

            this.endgamePenalty();
        },
        endgamePenalty: function() {
            if ( this.gameStore.currentGame.totalScoreProgress < this.gameStore.penalty.diff[this.userStore.user.options.diff].successRate ) {
                this.gameStore.currentGame.penalty = this.$root.calcPenalty();
                this.gameStore.currentGame.penaltyHistory.push({
                    text: "Success rate below " + this.gameStore.penalty.diff[this.userStore.user.options.diff].successRate + "%",
                    val: this.gameStore.currentGame.penalty,
                });
            }

            //this.userStore.user.totals.score = 0;return;

            if ( this.gameStore.currentGame.penalty ) {
                this.gameStore.currentGame.totalScore = this.gameStore.currentGame.totalScore - this.gameStore.currentGame.penalty;
                if ( this.gameStore.currentGame.penalty >= this.userStore.user.totals.score ) {
                    this.userStore.user.totals.score = 0;
                } else {
                    this.userStore.user.totals.score = this.userStore.user.totals.score - this.gameStore.currentGame.penalty;
                }
            }
        },
        showGameSummary: function() {
            this.gameStore.currentGame.inProgress = 0;
            if ( this.$root.isBust() ) {
                this.$root.view('bust');
            } else {
                this.gameSummary = 1;
                this.$root.sfxObj.bgTune = this.$root.playSfx(this.$root.sfxObj.bgTune,this.sfxLib.bgScorecard,{bgTune:1});

                if ( this.gameStore.currentGame.penalty ) {
                    this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.penalty,{});
                }
            }
        },
        bustGame: function() {
            this.gameStore.currentGame.puzzle.totalValueCurrent = 0;
            this.gameStore.currentGame.gameboard.isLose = 1;
            this.gameStore.currentGame.gameboard.isLosePop = 1;

            var self = this;
            setTimeout(function(){
                self.$root.playSfx(self.$root.sfxObj.bgTune,'',{fadeOut:0.5});
                self.$root.playSfxList('bustgame',[
                    {sound:self.sfxLib.speech.bad,options:{tts:1}},
                    {sound:self.sfxLib.wordWrong,options:{volume:0.5}},
                ]);
            },250);

            this.updateSuccessRate();
        },
        newPuzzle: function(inGame) {
            var self = this;

            if ( inGame ) {
                this.$root.playSfx(this.sfxObj.winPhrase,'',{stop:1});
                this.sfxObj.winPhrase = 0;

                this.$root.playSfx(this.$root.sfxObj.bgTune,'',{stop:1});
                this.$root.sfxObj.bgTune = 0;

                this.sfxObj.nextPuzzle = this.$root.playSfx(this.sfxObj.nextPuzzle,this.sfxLib.nextPuzzle,{});
            }

            if ( this.gameStore.currentPuzzles.length ) {
                this.runPuzzle(this.gameStore.currentPuzzles[this.gameStore.currentGame.puzzleIdx]);

            } else {
                var postData = {
                    action: 'getGame',
                    history: this.gameStore.currentGame.puzzleIds,
                    //puzzleId: 29,
                    diff: this.userStore.user.options.diff,
                    ppr: this.userStore.user.options.ppr,
                    source: this.userStore.user.options.source,
                    appMode: this.userStore.users.appMode,
                    paidMode: this.userStore.users.paidMode,
                }

                fetch(this.$root.remoteAjax, {
                    method: 'post',
                    body: JSON.stringify(postData),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                }).then((response) => response.json())
                .then((data) => {
                    if ( !data ) {
                        self.$root.view('offline');
                    } else {
                        if ( data.hasOwnProperty('bundle') ) {
                            data = self.$root.decodeBundle(data.bundle);
                        }
                        
                        self.userStore.user.totals.guessTotal = 0;
                        self.userStore.user.totals.guessRight = 0;
                        self.userStore.user.totals.guessWrong = 0;
                        self.userStore.user.totals.successRate = 0;

                        self.gameStore.currentPuzzles = data;
                        self.gameStore.currentGame.roundComplete = 0;
                        self.gameStore.currentGame.gameboard.guessTotal = 0;

                        self.runPuzzle(data[0]);
                    }
                })
                .catch(console.error);
            }
        },
        runPuzzle(data) {
            var puzzleData = JSON.parse(data.puzzle);
            puzzleData.diff = data.diff;
            puzzleData.length = data.length;
            puzzleData.totalValue = this.puzzleValue(puzzleData);
            puzzleData.totalValueMax = this.puzzleValue(puzzleData);
            puzzleData.totalValueCurrent = this.puzzleValue(puzzleData);
            puzzleData.sourceRedacted = this.redactSource(puzzleData.author);
            this.gameStore.currentGame.puzzle = puzzleData;
            
            Object.assign(this.gameStore.currentGame.gameboard, {
                isWin: 0,
                isLose: 0,
                token: 0,
                answer: -1,
                cluelength: 0,
                isStart: 1,
                guessRight: 0,
                guessWrong: 0,
                wrong: 0,
            });
            
            //this.gameStore.currentGame.puzzleIds.push(puzzleData.puzzleId);
            this.gameStore.currentGame.inProgress = 1;
            this.gameStore.currentGame.puzzleIdx++;
            this.gameStore.currentPuzzles[this.gameStore.currentGame.puzzleIdx-1].puzzle = puzzleData;

            this.setupStage();

            console.log(data.id,puzzleData);
            //console.log(this.gameStore.currentGame);
        },
        startGame: function() {
            this.$root.playSfx(this.$root.sfxObj.tmp,'',{stop:1});

            this.gameSummary = 0;
            this.clueTimer = JSON.parse(JSON.stringify(this.gameStore.currentGame.gameboard.clueTimer));
            this.$root.playSfx(this.$root.sfxObj.bgTune,'',{stop:1});
            this.$root.sfxObj.startGame = this.$root.playSfx(this.$root.sfxObj.startGame,this.sfxLib.startGame,{});
            this.gameStore.currentPuzzles = [];
            this.gameStore.currentGame.penalty = 0;
            this.gameStore.resetGame();
            this.newPuzzle(0);
        },
        cssAnim: function(popupOut) {
            if ( popupOut ) {
                return 'anim puff-out-center';
            } else {
                var anims = [ 'scale-in-center', 'rotate-in-center', 'swirl-in-fwd', 'swirl-in-bck', 'slide-in-top', 'bounce-in-top', 'roll-in-top' ];
                return 'anim ' + this.$root.shuffle(anims)[0];
            }
        },
    },
    mounted() {
        this.startGame();
    }
}
</script>

<style>
.nextBtn {
    border-radius: 0;
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
}

.nextBtn .game-icon {
    transform: rotate(-50deg);
}

.progress-wrapper {
    position: absolute;
    left: 0;
    bottom: calc(0px + env(safe-area-inset-top));
    width: 100%;
}

.by-source,
.phrase-author {
    color: #802e0b;
    letter-spacing: 0.1rem;
    text-shadow: 1px 1px 4px #FFFF00;
}

.phrase-author {
    font-family: 'Special Elite', cursive;
}

.phrase-author span.redact {

}

.score-top,
.score-bottom {
    background-color: rgba(25,25,25,1);
    color: #EEEEEE;
}

.score-bottom .success-rate-text {
    color: #1c5a07;
}

#totalScore {
    width: 100%;
    height: 1.25rem;
    background-color: white;
    color: black;
}

#totalScoreProgress {
    top: 0;
    left: 0;
    height: 100%;
    --progress: 0%;
    width: var(--progress);
}

#totalScoreProgress.progress {
    border-right: 6px solid #0c700c;
    border-radius: 0;
    opacity: 0.5;
}

.summary-penalty {
    margin:0 auto;
    margin-top: 3rem;
    width: 80%;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.95);
    background-image: url('@/assets/gfx/referee-with-yellow-card.png');
    background-position: -115px;
    background-size: center;
    background-repeat: no-repeat;
    background-color: var(--bs-danger);
    padding: 0.5rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
}

@media only screen and (min-width: 1200px) {
    .summary-penalty {
        background-position: -30px;
    }
    .summary-penalty .inner {
        margin-right: 8px;
    }
}

@media only screen and (min-width: 1400px) {
    .summary-penalty .inner {
        margin-right: 100px;
    }
}

@media only screen and (max-width: 1200px) {
    .summary-penalty .inner {
        margin-right: 30px;
    }
}

@media only screen and (max-width: 600px) {
    .summary-penalty {
        background-position: -190px;
    }
    .summary-penalty .inner {
        margin-right: 235px;
    }
}

#tvcAnimate {
    width: 10rem;
    background-color: yellow;
    color: black;
    text-align: center;
}

#tvcAnimate.bust {
    background-color: red !important;
    color: white !important;    
}

.tvcAnimate {
    background-color: red !important;
    color: white !important;
    transition-property: all;
    transition-duration: 0.5s;
    animation-name: tvcAnimateGrow;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes tvcAnimateGrow {
    0% { transform: scale(1);  }
    50% { transform: scale(0.90);  }
    100% { transform: scale(1); }
}

#tvcAnimate.celeb {
    animation: celebVal 2s ease 0s 1 normal forwards;
}
@keyframes celebVal {
	0%,
	100% {
		transform: rotate(0deg);
		transform-origin: 50% 50%;
	}

	10% {
		transform: rotate(8deg);
	}

	20%,
	40%,
	60% {
		transform: rotate(-10deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(10deg);
	}

	80% {
		transform: rotate(-8deg);
	}

	90% {
		transform: rotate(8deg);
	}
}

.phrase-wrapper-wide {
    width:2000px;
    overflow-x: hidden;
    padding: 0 !important;
    margin-bottom:1rem !important;
}

.phrase-play {
    color: #268db9;
    text-transform: uppercase;
    width: fit-content;

}

.phrase-win {
    color:#0c700c;
    font-size: 1.25rem;
    text-transform: uppercase;
    transition-property: font-size,color;
    transition-duration: 0.5s;
    transition-timing-function: linear;
}

.phrase-win .author {
    font-size: 85%;
    font-style: italic;
}


@media only screen and (max-width: 100px) {
    .phrase-win {
        font-size: 1.5rem;
    }
}

.phrase-inner {
    font-family: 'Special Elite', cursive;
}

.phrase-inner-lg {
    width:100%;
    display:flex;
    justify-content: center;
    margin-bottom: 2rem;
}

#phrase-desc-wrapper {
    height: var(--phrase-desc-h);
    max-height: var(--phrase-desc-h);
    overflow-y: auto;
}

.phrase-desc {
    color: #333333;
    font-family: 'Libre Baskerville', serif;
    margin-bottom: 2rem;
}

.lquo:before {
    font-family:Georgia, "Times New Roman", Times, serif;
    content: open-quote;
}

.rquo:after {
    font-family:Georgia, "Times New Roman", Times, serif;
    content: close-quote;
}

#gameSummary {
    overflow-y: auto;
}

#gameSummary.height {
    height: var(--game-summary-h);
    max-height: var(--game-summary-h);
}

@media only screen and (max-width: 600px) {
    .phrase-desc {
        font-size: 1rem;
        padding: 1rem;
    }
}

#slots {
    --ws-scale: 1.0;
    transform: scale(var(--ws-scale));
    transform-origin: top;
}

#gameSummary .badge {
    width: 6rem;
}

#gameboard-wrapper {
    height: var(--gameboard-h);
    max-height: var(--gameboard-h);
    overflow-y: auto;
}

.blinking-cursor {
  height: 1.75rem;
  width: 1rem;
  background-color: gray;
  animation: blink step-end infinite;
  animation-duration: 1s;
}
@keyframes blink {
  from, to {
    background-color: gray;
  }
  50% {
    background-color: white;
  }
}

.blank-guess {
    margin-bottom: 0.25rem;
    height: 1.25rem;
    width: 2.5rem;
    border: 3px solid rgba(255,255,0,0.95);
    border-radius: var(--bs-border-radius-pill);
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
    background-color: rgb(60,181,235);
    animation: blankguess 1.5s ease-out infinite;
}

.blank-guess.bg-activate {
    width:auto;
    height:auto;
    margin-bottom: 0;
    background-color: rgb(60,181,235) !important;
}

.blank-noguess {
    margin-bottom: 0.25rem;
    height: 1.25rem;
    width: 2.5rem;
    border: 3px solid rgba(60,181,235,0.75);
    border-radius: var(--bs-border-radius-pill);
}

@keyframes blankguess {
  0% {
    border: 3px solid rgba(255,255,0,0.95);
  }
  50% {
    border: 3px solid rgba(255,255,0,0);
  }
  100% {
    border: 3px solid rgba(255,255,0,0.95);
  }

}

.popup {
    position: absolute;
    width:100%;
    bottom: calc(6em + env(safe-area-inset-top));
}

.popup-body {
    overflow: hidden;
    width: 100%;
    max-width:500px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.95);
    top: 0;
    font-family: 'Luckiest Guy', cursive;
    padding:5rem;
    font-size: 4rem;
    cursor: pointer !important;
}

.popup.shieldblock .popup-body {
    border-radius: 0;
    background-image: none;
    box-shadow: none;
    font-size: 200%;
    color: #cc3300;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.75);

}

.popup-body.bonus-1 {
    background-image: url('@/assets/gfx/1688970030lime-green-sunbeams-pattern.png');
}
.popup-body.bonus-2 {
    background-image: url('@/assets/gfx/yellowandorangesunrays.png');
}
.popup-body.bonus-3 {
    background-image: url('@/assets/gfx/rainbow-balloon-clover-clipart-md.png');
    background-color: var(--bs-primary);
}
.popup-body.lose-1 {
    background-image: url('@/assets/gfx/sadman.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-color: var(--bs-warning);
}


.popup.bonus-award {
    top: 12%;
    transform: scale(0.75);
    transform-origin: top
}
.popup.bonus-award .popup-body {
    border-radius: 25%;
    width: 95%;
    padding: 1rem 1rem 0 0;
    background-image: url('@/assets/gfx/1608216272dotted-pattern-swirl.png');
    background-color: var(--bs-warning);
}
.popup.bonus-award .popup-body h1.award{
    font-size: 6rem;
}

.popup-body h1 {
    font-size: 75%;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.75);
    color: #FFF;
}

.popup-body h1.award {
    color: #ffff00;
}

.popup-body h2 {
    font-size: 45%;
    text-shadow: 2px 2px 2px rgba(250,250,250,0.75);
    color: #333;
}

.popup-body img.mascot {
    width:50%;
    margin-left:-6rem;
}


@media only screen and (max-width: 600px) {
    .popup {
        width: 100%;
        margin:0;
    }
    .popup-body {
        padding:2rem;
    }
    .popup-body h1 {
        font-size: 57%;
    }
    .popup-body h2 {
        font-size: 40%;
    }
    .popup-body img.mascot {
        margin-left:-4rem;
    }
}



</style>