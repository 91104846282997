<template>

<div id="userhud" class="userhud mb-1" :class="{ 'anim slide-out-right' : switchUserAnim==1, 'anim slide-in-left' : switchUserAnim==2 }" >
        <div class="position-relative fs-4 d-flex align-items-start justify-content-between">
            <div class="position-absolute logo-main text-white text-decoration-none" @click="clickLogo()" :key="$root.ttsActive">
                <img class="my-1" :class="{ 'd-none' : $root.ttsActive }" src="@/assets/gfx/logo1.png" />
                <img class="my-1" :class="{ 'd-none' : !$root.ttsActive, 'd-block' : $root.ttsActive }" src="@/assets/gfx/logo1-tts.png" />
            </div>

            <div class="w-100 d-flex align-items-center justify-content-end fs-3 pe-1">
                    <a class="px-1 fs-3 text-decoration-none clickfx" :style="{ color: userStore.user.styleColor }" href="#" @click="$root.view('player');">
                        <img class='icon-avatar' :src="$root.avatarLib[userStore.user.avatarLevel][userStore.user.avatar]" />
                    </a>
                    
                    <a class="fs-5 text-decoration-none text-nowrap clickfx" :style="{ color: userStore.user.styleColor }" href="#" @click="$root.view('player');">{{ userStore.user.name }}</a>
            </div>
        </div>

        <div v-if="0" class="w-100 ps-1 pe-1">
            <div class="mt-1 d-flex justify-content-start awards-container" v-if="$root.calcAwards()">
                <template v-for="awd in $root.calcAwards()">
                    <i v-if="$root.calcAwardsTotal()<=12" v-for="idx in awd.qty" class="fs-2" :class="awd.icon" :style="'color: ' + this.gameStore.options.ppr[awd.ppr].color"></i>
                    <div v-if="$root.calcAwardsTotal()>12" class="d-flex flex-column align-items-center ms-2">
                        <i class="fs-2" :class="awd.icon" :style="'color: ' + this.gameStore.options.ppr[awd.ppr].color"></i> 
                        <span :style="'color: ' + this.gameStore.options.ppr[awd.ppr].color">x{{ awd.qty }}</span>
                    </div>
                </template>
            </div>
        </div>

        <div class="toolbar mt-2 w-100 ps-1 pe-1 d-flex align-items-center justify-content-end fs-4">
            <div class="badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center me-2" @click="activateTool(userStore.user.tools.shield)">
                <span class="me-1">
                    {{ userStore.user.tools.shield.total }}
                </span>
                <img class="icon-shield" src="@/assets/gfx/shield.png" :class="{ 'anim pulsate-fwd active' : userStore.user.tools.shield.active }" />
            </div>

            <div class="badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center me-2" @click="activateTool(userStore.user.tools.wand)">
                <span class="me-1">
                    {{ userStore.user.tools.wand.total }}
                </span>
                <img class="icon-wand" src="@/assets/gfx/wand.png" :class="{ 'anim pulsate-fwd active' : userStore.user.tools.wand.active }" />
            </div>

            <div class="badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center me-2" @click="activateTool(userStore.user.tools.unmask)">
                <span class="me-1">
                    {{ userStore.user.tools.unmask.total }}
                </span>
                <img class="icon-unmask" src="@/assets/gfx/binocular.png" :class="{ 'anim pulsate-fwd active' : userStore.user.tools.unmask.active }" />
            </div>

            <div class="badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center me-2" @click="activateTool(userStore.user.tools.spotlight)">
                <span class="me-1">
                    {{ userStore.user.tools.spotlight.total }}
                </span>
                <img class="icon-spotlight" src="@/assets/gfx/magnifyglass.png" :class="{ 'anim pulsate-fwd active' : userStore.user.tools.spotlight.active }" />
            </div>

            <a href="#" class="px-4 badge rounded-pill bg-yellow text-dark d-flex flex-column justify-content-center align-items-center text-decoration-none text-dark" :class="{ 'clickfx' : userStore.user.totals.score }" @click="userStore.user.totals.score ? $root.view('shop') : ''">
                <span class="me-1">
                    {{  this.$root.numFormat(userStore.user.totals.score) }}
                </span>
                <img class="icon-spotlight" src="@/assets/gfx/coin.png" />
            </a>
        </div>
    </div>

    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import confirmDialog from '@/components/confirmdialog.vue';

export default {
  name: 'userhud',
  components: { 
        confirmDialog
    },
  props: {

  },
  data: () => ({   
      userStore: useUserStore(),
      gameStore: useGameStore(),
      switchUserAnim: 0,
  }),
  methods: {
    clickLogo: function() {
        if ( this.$root.page == 'setup' ) {
            if ( !this.userStore.users.welcome ) {
                this.$root.sfxObj.tmp = this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.speech.welcome,{tts:1});
            }
        } else {
            this.$root.view('setup');
        }
    },
    activateTool: function(tool) {
        if ( this.gameStore.currentGame.gameboard.isStart && this.gameStore.currentGame.gameboard.isReady && tool.total > 0 ) {
            if ( tool.active ) {
                if ( tool.sound.off ) {
                    this.$root.playSfx(this.$root.sfxObj.tmp,tool.sound.off,{});
                }
                tool.active = 0;
            } else if ( !tool.active ) {
                if ( tool.sound.on ) {
                    this.$root.playSfx(this.$root.sfxObj.tmp,tool.sound.on,{});
                }
                tool.active = 1;

                if ( tool.id == 'shield' ) {
                    this.confirmShield();                    
                }

                if ( tool.id == 'wand' ) {
                    this.confirmWand();                    
                }

                if ( tool.id == 'unmask' ) {
                    this.confirmUnmask();                    
                }

                if ( tool.id == 'spotlight' ) {
                    this.confirmSpotlight();                    
                }
            }
        }
    },
    async confirmShield() {
        const ok = await this.$refs.confirmDialog.show({
            title: 'Immunity Shield',
            message: this.userStore.user.tools.shield.description,
            okButton: 'Nope',
            cancelButton: 'Use it',
        });
        if (ok) {
            //bail
            this.userStore.user.tools.shield.active = 0;
        } 
    },
    async confirmWand() {
        const ok = await this.$refs.confirmDialog.show({
            title: 'Solving Wand',
            message: this.userStore.user.tools.wand.description,
            okButton: 'Nope',
            cancelButton: 'Use it',
        });
        if (ok) {
            //bail
            this.userStore.user.tools.wand.active = 0;
        } else {
            //ok
            this.$parent.solveWord();
        }
    },
    async confirmUnmask() {
        const ok = await this.$refs.confirmDialog.show({
            title: 'Unmask',
            message: this.userStore.user.tools.unmask.description,
            okButton: 'Nope',
            cancelButton: 'Use it',
        });
        if (ok) {
            //bail
            this.userStore.user.tools.unmask.active = 0;
        } else {
            //ok
            this.$parent.unmaskSource();
        }
    },
    async confirmSpotlight() {
        const ok = await this.$refs.confirmDialog.show({
            title: 'Spotlight',
            message: this.userStore.user.tools.spotlight.description,
            okButton: 'Nope',
            cancelButton: 'Use it',
        });
        if (ok) {
            //bail
            this.userStore.user.tools.spotlight.active = 0;
        } else {
            //ok
            this.$parent.enableSpotlight();
        }
    }
  },
}
</script>

<style>
.toolbar {
    transform: scale(0.8);
    transform-origin: right;
}
</style>