<template>
    <popup-modal ref="popup">
        <h2 style="margin-top: 0" v-html="title"></h2>
        <p class="text-center" v-html="message"></p>
        <div class="btns">
            <button type="button" class="w-100 border-0 bg-secondary ok-btn" @click="_confirm">{{ okButton }}</button>
        </div>
    </popup-modal>
</template>

<script>
import PopupModal from '@/components/popupmodal.vue';

export default {
    name: 'alertDialog',

    components: { PopupModal },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        title: undefined,
        message: undefined,
        okButton: 'OK',
        
        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            if (opts.okButton) {
                this.okButton = opts.okButton
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },
    },
}
</script>

<style scoped>
.btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid #1e73b2;
}

.ok-btn {
    padding: 0.5em 1em;
    background-color: #bb3e10;
    color: #f1f3ac;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;

}
</style>
