<template>

    <div class="bg-white" style="padding-bottom:5rem;overflow-y:auto;height:100%;">

        <div class="p-2 text-start">
            <h3 class="text-primary">How to Play</h3>
            <ul class="list-group list-group-flush text-start">
                <li class="list-group-item">
                    Move the words into place to complete each movie quote!
                </li>
                <li class="list-group-item">
                    Click the player name to edit and create new players.
                </li>
                <li class="list-group-item">
                    <img class="icon-shield icon-small" src="@/assets/gfx/shield.png" /> <span class="text-primary">{{ userStore.user.tools.shield.name }}</span> {{ userStore.user.tools.shield.description  }}
                </li>
                <li class="list-group-item">
                    <img class="icon-shield icon-small" src="@/assets/gfx/wand.png" /> <span class="text-primary">{{ userStore.user.tools.wand.name }}</span> {{ userStore.user.tools.wand.description  }}
                </li>
                <li class="list-group-item">
                    <img class="icon-shield icon-small" src="@/assets/gfx/binocular.png" /> <span class="text-primary">{{ userStore.user.tools.unmask.name }}</span> {{ userStore.user.tools.unmask.description  }}
                </li>
                <li class="list-group-item">
                    <img class="icon-shield icon-small" src="@/assets/gfx/magnifyglass.png" /> <span class="text-primary">{{ userStore.user.tools.spotlight.name }}</span> {{ userStore.user.tools.spotlight.description  }}
                </li>
            </ul>
        </div>

        <hr/>
        <div class="p-2 text-start">
            <h4 class="text-primary">Reset Data</h4>
            <p>
                To delete all players and their data &mdash; 
                <button href="#" class="btn btn-primary btn-sm ext-decoration-none fs-6" @click="resetData()">Click here</button>
            </p>
        </div>

        <hr/>
        <div class="p-2 text-start">
            <h4 class="text-primary">Acknowledgments</h4>
            <ul class="list-group list-group-flush text-start">
                <li class="list-group-item">
                    Background music - <a target="_blank" href="https://freemusicarchive.org/music/Origami_Repetika/the-happy-puppy-collection">Origami Repetika</a> and <a target="_blank" href="https://freemusicarchive.org/music/zane-little/a-bag-of-chips">Zane Little</a>
                </li>
                <li class="list-group-item">
                    <a target="_blank" href="https://www.flaticon.com/free-animated-icons/animals" title="animals animated icons">Animals animated icons created by Freepik - Flaticon</a>
                </li>
                <li class="list-group-item">
                    Game testing and consulting - <a target="_blank" href="https://adamosterweil.com/">Adam Osterweil</a>
                </li>
            </ul>
        </div>

        <div class="mt-2 d-flex justify-content-center">
            <button class="mt-2 btn btn-primary text-uppercase" type="button" @click="this.$root.view('setup');">close</button>
        </div>

    </div>

    <alert-dialog ref="alertDialog"></alert-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

export default {
    components: { 
        alertDialog, confirmDialog
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
    }),
    methods: {
        async resetData() {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Reset All Data',
                message: 'Are you sure you want to permanently delete all players?',
                okButton: 'Delete',
            });
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.userStore.resetUsers();
                this.$root.view('setup');
            } else {
                //bail
            }
        },         
    }
}
</script>

<style>
</style>