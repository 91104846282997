<template>

    <div class="glitch-wrapper">
        <div class="text-uppercase d-flex align-items-center justify-content-center">

            <div class="fs-2 glitch" :data-text="userCopy.name">{{ userCopy.name }}</div>
        </div>
    </div>

    <div class="w-100 m-5 pt-5">
      <span class="stamp-wrapper"><span class="stamp">bankrupt</span></span>
      <span class="stamp-wrapper" style="margin-top:-2rem;"><span class="stamp is-nope">broke</span></span>
      <span class="stamp-wrapper" style="margin-top:-4rem;"><span class="stamp is-notapproved">&nbsp;&nbsp;busted&nbsp;&nbsp;</span></span> 
    </div>

    <div v-if="stampsComplete" class="p-4 text-center fs-2 anim puff-in-center">

      {{ userCopy.name }} has been removed from the game.
      
      <br/><br/><br/><br/><br/>
      <button type="button" class="btn btn-lg btn-success text-white text-uppercase clickfx" @click="$root.restart()">
         Start Over
      </button>
    </div>

    <div class="d-flex justify-content-center">
      <img class="my-1" style="width:10rem;" :class="{ 'd-none' : $root.ttsActive }" src="@/assets/gfx/logo1.png" />
      <img class="my-1" style="width:10rem;" :class="{ 'd-none' : !$root.ttsActive, 'd-block' : $root.ttsActive }" src="@/assets/gfx/logo1-tts.png" />
    </div>

    <alert-dialog ref="alertDialog"></alert-dialog>
    <confirm-dialog ref="confirmDialog"></confirm-dialog>
</template>

<script>
import { useUserStore,useGameStore } from '@/gamestore.js';
import alertDialog from '@/components/alertdialog.vue';
import confirmDialog from '@/components/confirmdialog.vue';

export default {
    components: { 
        alertDialog, confirmDialog
    },
    data: () => ({  
        userStore: useUserStore(),
        gameStore: useGameStore(),
        glitchKey: 1,
        stampsComplete: 0,
        userCopy: {},
    }), 
    methods: {
         playStamps: function() {
            var s = document.querySelectorAll('.stamp-wrapper');
            var to = 0;
            var vol = 0.5;
            var self = this;

            for (let x = 0; x < s.length; x++) {
               to = to + 500;
               setTimeout(function(){
                  s[x].classList.add('anim','stamp-in-center');
                  self.$root.playSfx(self.$root.sfxObj.tmp,self.$root.sfxLib.stamp,{volume: vol});
                  vol = vol + 0.25;
                  if ( x == s.length-1 ) {
                     self.stampsComplete = 1; 
                  }
               }, to);
            }
         }
    },
    mounted() {
      this.userCopy = JSON.parse(JSON.stringify(this.userStore.user));

      if ( Object.keys(this.userStore.otherUsers).length ) {
         this.userStore.users.active = Object.keys(this.userStore.otherUsers)[0];
      } else {
         var userId = this.userStore.newUser({name:'Guest Player', avatar: 0});
         this.userStore.users.active = userId;
      }

      this.userStore.deleteUser(this.userCopy.id);
      //this.$root.playSfx(this.$root.sfxObj.tmp,this.$root.sfxLib.bust,{}); 

      var self = this;
      self.$root.playSfxList('bustplayer',[
         {sound:self.$root.sfxLib.bust,options:{volume:1}},
         {sound:self.$root.sfxLib.speech.bustplayer,options:{tts:1,ttsforce:1}},
      ]);

      this.glitchKey = 2;
      this.playStamps();
    }
}
</script>

<style>
.glitch-wrapper {
   position:absolute;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   opacity: 1;
   animation: flickerI 2s linear  reverse infinite;
}

.glitch {
   position: relative;
   font-weight: bold;
   color: #333333;
   letter-spacing: 3px;
   z-index: 1000;
}

.glitch:before {
   content: attr(data-text);
   position: absolute;
   top: 0;
   left: -2px;
   width: 100%;
   overflow: hidden;
   clip: rect(0, 900px, 0, 0);
   animation: noise-before 3s infinite linear alternate-reverse;
}

.glitch-avatar:before {
    font-family: 'game-icons' !important;
}

.glitch:after {
   content: attr(data-text);
   position: absolute;
   top: 0;
   left: 2px;
   width: 100%;
   overflow: hidden;
   clip: rect(0, 900px, 0, 0);
   animation: noise-after 2s infinite linear alternate-reverse;
}

.glitch-avatar:after {
    font-family: 'game-icons' !important;
}

@keyframes noise-before {
   0% {
      clip: rect(61px, 9999px, 52px, 0);
   }
   5% {
      clip: rect(33px, 9999px, 144px, 0);
   }
   10% {
      clip: rect(121px, 9999px, 115px, 0);
   }
   15% {
      clip: rect(144px, 9999px, 162px, 0);
   }
   20% {
      clip: rect(62px, 9999px, 180px, 0);
   }
   25% {
      clip: rect(34px, 9999px, 42px, 0);
   }
   30% {
      clip: rect(147px, 9999px, 179px, 0);
   }
   35% {
      clip: rect(99px, 9999px, 63px, 0);
   }
   40% {
      clip: rect(188px, 9999px, 122px, 0);
   }
   45% {
      clip: rect(154px, 9999px, 14px, 0);
   }
   50% {
      clip: rect(63px, 9999px, 37px, 0);
   }
   55% {
      clip: rect(161px, 9999px, 147px, 0);
   }
   60% {
      clip: rect(109px, 9999px, 175px, 0);
   }
   65% {
      clip: rect(157px, 9999px, 88px, 0);
   }
   70% {
      clip: rect(173px, 9999px, 131px, 0);
   }
   75% {
      clip: rect(62px, 9999px, 70px, 0);
   }
   80% {
      clip: rect(24px, 9999px, 153px, 0);
   }
   85% {
      clip: rect(138px, 9999px, 40px, 0);
   }
   90% {
      clip: rect(79px, 9999px, 136px, 0);
   }
   95% {
      clip: rect(25px, 9999px, 34px, 0);
   }
   100% {
      clip: rect(173px, 9999px, 166px, 0);
   }
}

@keyframes noise-after {
   0% {
      clip: rect(26px, 9999px, 33px, 0);
   }
   5% {
      clip: rect(140px, 9999px, 198px, 0);
   }
   10% {
      clip: rect(184px, 9999px, 89px, 0);
   }
   15% {
      clip: rect(121px, 9999px, 6px, 0);
   }
   20% {
      clip: rect(181px, 9999px, 99px, 0);
   }
   25% {
      clip: rect(154px, 9999px, 133px, 0);
   }
   30% {
      clip: rect(134px, 9999px, 169px, 0);
   }
   35% {
      clip: rect(26px, 9999px, 187px, 0);
   }
   40% {
      clip: rect(147px, 9999px, 137px, 0);
   }
   45% {
      clip: rect(31px, 9999px, 52px, 0);
   }
   50% {
      clip: rect(191px, 9999px, 109px, 0);
   }
   55% {
      clip: rect(74px, 9999px, 54px, 0);
   }
   60% {
      clip: rect(145px, 9999px, 75px, 0);
   }
   65% {
      clip: rect(153px, 9999px, 198px, 0);
   }
   70% {
      clip: rect(99px, 9999px, 136px, 0);
   }
   75% {
      clip: rect(118px, 9999px, 192px, 0);
   }
   80% {
      clip: rect(1px, 9999px, 83px, 0);
   }
   85% {
      clip: rect(145px, 9999px, 98px, 0);
   }
   90% {
      clip: rect(121px, 9999px, 154px, 0);
   }
   95% {
      clip: rect(156px, 9999px, 44px, 0);
   }
   100% {
      clip: rect(67px, 9999px, 122px, 0);
   }
}

@keyframes flickerI {
  0% {
    opacity: 0.4;
  }
  5% {
    opacity: 0.5;
  }
  10% {
    opacity: 0.6;
  }
  15% {
    opacity: 0.85;
  }
  25% {
    opacity: 0.5;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.25;
  }
  45% {
    opacity: 0.5;
  }
  60% {
    opacity: 1;
  }
  70% {
    opacity: 0.85;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.stamp-wrapper {
   display: none;
}

.stamp {
  transform: rotate(12deg);
	color: #555;
	font-size: 3rem;
	font-weight: 700;
	border: 0.25rem solid #555;
	display: inline-block;
	padding: 0.25rem 1rem;
	text-transform: uppercase;
	border-radius: 1rem;
	font-family: 'Courier';
	-webkit-mask-image: url('@/assets/gfx/grunge.png');
  -webkit-mask-size: 944px 604px;
  mix-blend-mode: multiply;
}

.is-nope {
  color: #D23;
  border: 0.5rem double #D23;
  transform: rotate(3deg);
	-webkit-mask-position: 2rem 3rem;
  font-size: 2rem;  
}

.is-notapproved {
	color: #6f0d7f;
	border: 0.5rem solid #6f0d7f;
	-webkit-mask-position: 13rem 6rem;
	transform: rotate(-14deg);
  border-radius: 0;
} 

.is-draft {
	color: #6f0d7f;
	border: 1rem double #6f0d7f;
	transform: rotate(-5deg);
  font-size: 3rem;
  font-family: "Open sans", Helvetica, Arial, sans-serif;
  border-radius: 0;
  padding: 0.5rem;
} 
</style>