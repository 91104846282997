<template>
    <div ref="wordCloud">
        <div class="d-flex align-items-center justify-content-center flex-wrap">
            <template v-for="wordObj in $parent.wordCloud">
                <h2 class="m-1">
                    <span class="badge rounded-pill bg-primary fw-normal text-uppercase" :class="{ 'bg-primary' : !isSelected(wordObj.token), 'bg-activate blank-guess' : isSelected(wordObj.token), 'assigned' : isAssigned(wordObj.token), 'unassigned' : !isAssigned(wordObj.token) }" :data-token="wordObj.token" @click="$parent.selectWord(wordObj.token)" @touchstart="$parent.selectWord(wordObj.token)" v-draggable:wordSlot.image="{ image: dragImage(wordObj.token), data: { data: { tk : wordObj.token } } }" @v-drag-end="dragEnd">
                    {{ wordObj.word }}
                    </span>
                </h2>
            </template>
        </div>
    </div>
</template>
  
<script>
import { draggable, droppable } from 'v-drag-drop';
import { useUserStore,useGameStore } from '@/gamestore.js';

  export default {
    name: 'wordcloud',
    props: ['assignWord'],
    directives: {
        draggable, droppable
    },
    data: () => ({   
        userStore: useUserStore(),
        gameStore: useGameStore(),

    }),
    methods: {
        init: function() {
            this.$parent.gameboardKey = 2;
        },
        dragEnd: function(a,b,c) {
            this.$parent.assignWord.started = 0;
            this.$parent.assignWord.source = 0;
        },
        dragImage: function(tk) {
            //console.log(this.$parent.wordTk(tk));
            var token = this.gameStore.currentGame.puzzle.tokens[tk].word;

            var fontSize = 16;
            const canvas = document.createElement("canvas");
            canvas.width = token.length * fontSize;
            canvas.height = fontSize * 1.5;
            const ctx = canvas.getContext("2d");
            ctx.fillStyle = "blue";
            ctx.font = "bold " + fontSize + "px Arial";
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(token.toUpperCase(), (canvas.width / 2), (canvas.height / 2));
            ctx.strokeStyle = "blue";
            ctx.beginPath();
            ctx.roundRect(0, 0, canvas.width, canvas.height, [20]);
            ctx.stroke();

            const myImage = new Image();
            myImage.src = canvas.toDataURL();

            return myImage;
        },
        getWords: function() {
            return this.$parent.wordcloud();
        },
        isSelected: function(tk) {
            return this.$parent.assignWord.started && this.$parent.assignWord.source == tk;
        },
        isAssigned: function(tk) {
            return Object.values(this.assignWord.slots).includes(tk);
        },
    },
    mounted() {
        this.init();
    }
  }


</script>
  
  <style>
    
    .wordcloud {
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
    .assigned {
        background-color: #CCC !important;
        opacity: 0.75;
        transition: all 0.25s ease-out;
    }
    .unassigned {
        opacity: 1;
        transition: all 0.25s ease-in;
    }

  </style>
  